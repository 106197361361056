import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import _ from 'lodash'
import {
    Grid,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
    withStyles,
    Typography
} from '@material-ui/core';
import {
    CardContainer,
    Button,
    CustomInput,
    GridItem,
    NewDataTable
} from "components";
import DataContext from 'context/Data'
import utils from 'utils/utils'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  cardContainer: {
    height: '800px',
    // width: '100%'
  },
  toolBar: {
    display: 'flex',
    width: '100%',
    paddingBottom: '.5rem'
  },
  headerToolBar: {
    display: 'inline-flex',
    marginLeft: '1rem'
  },
  exportRecord: {
    marginLeft: 'auto'
  }
});

class DownloadReport extends Component {
    static contextType = DataContext;

    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.keys = [];
        this.clientKeys = [];

        this.state = {
            loading: false,
            report_type: 'customer',
              // WMS Input
            type_result: {},
            type: '',
            category: '',
            date_from: '',
            date_to: '',
              // Client Input
            client_type_options: {},
            client_type: '',
            clientCategory: '',
            client_date_from: '',
            client_date_to: '',
        };

        document.title = 'Download Report';
    }

    render() {
      const { report_type } = this.state;
        return (
          <Grid container spacing={2}>
                {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

                <GridItem xs={12} sm={6} md={6}>
                  {report_type == 'customer' ? this.renderClientForm() : this.renderWarehouseForm()}
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  {
                    // report_type == 'customer' ? this.renderDefinitionTable() : this.renderWarehouseDefinitionTable()
                    utils.getRoleType() == 'customer' && this.renderDefinitionTable()
                  }
                </GridItem>
                
                <GridItem xs={12} sm={6} md={6}>
                  {utils.getRoleType() == 'warehouse' && this.renderWarehouseForm()}
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  {utils.getRoleType() == 'warehouse' && this.renderWarehouseDefinitionTable()}
                </GridItem> 
            </Grid>
        );
    }

    componentWillMount() {
        this.loadTypes();
    }

    // load product table
    loadTypes = () => {
      let req = axios({
        method: 'get',
        url: `${utils.getBaseUrl('customer')}/report`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
      });

      this.setState({loading: true});
      req.then(this.loadTypesSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }
    loadTypesSuccess = (resp) => {
        this.setState({loading: false});

        // alert error if any
        if (resp.data.Error) {
            this.context.alert(resp.data.Error);
            return;
        }

        if (!resp.data) {
            this.context.alert("Load report templates error");
            return;
        }

        let wms = {};
        let client = {};
        // WMS Type Options
        let keys = [];
        let categories = [];
        for (let category in resp.data) {
          let type_list = {};
          let empty = true; // mark if this category is empty
          for (let option of resp.data[category]) {
            if (!option.warehouse_id) continue; // skip report for ALL, only process report with warehouse id
            type_list[option.value] = option;
            keys.push(option.value);
            empty = false;
          }
          if (!empty) {
            categories.push(category);
            wms[category] = type_list;
          }
        }
        let defaultType = keys.length > 0 ? keys[0] : '';
        let defaultCategory = categories.length > 0 ? categories[0] : '';
        this.keys = keys;
        // client no category
        let clientKeys = [];
        for (let category in resp.data) {
          let type_list = {};
          for (let option of resp.data[category]) {
            if (option.warehouse_id) continue; // skip warehouse report, only process report for ALL
            client[option.value] = option;
            clientKeys.push(option.value);
          }
        }
        let clientDefaultType = clientKeys.length > 0 ? clientKeys[0] : '';
        this.clientKeys = clientKeys;

        this.setState({
          // wms report
          type_result: wms, 
          type: defaultType, 
          category: defaultCategory, 
          // client report
          client_type_options: client,
          client_type: clientDefaultType,
        });
    }

    // downloadReport = (e) => {
    //     e.preventDefault();

    //     let { type, date_from, date_to } = this.state;

    //     let dtFrom = new Date(date_from);
    //     let dtTo = new Date(date_to);

    //     if (dtFrom > dtTo) {
    //         this.context.alert("Date From can not be later than Date To");
    //         return;
    //     }

    //     if (!date_from) date_from = 'empty';
    //     if (!date_to)  date_to = 'empty';

    //     // let url = `${utils.getBaseUrl('customer')}/report/${localStorage.getItem('warehouse_id')}/${localStorage.getItem('customer_id')}/${type}/${date_from}/${date_to}`;
    //     let url = `${utils.getBaseUrl('customer')}/report/${localStorage.getItem('warehouse_id')}/${localStorage.getItem('token')}/${type}/${date_from}/${date_to}`;
    //     let w = window.open(url);
    //     if (!w) {
    //         this.context.alert('To enable download, please allow pop up window');
    //     } else {
    //       this.context.snackDisplay("Report download success.");
    //     }
    // }
    downloadReport = (e) => {
      e.preventDefault();

      let { type, date_from, date_to } = this.state;

      let dtFrom = new Date(date_from);
      let dtTo = new Date(date_to);

      if (dtFrom > dtTo) {
          this.context.alert("Date From can not be later than Date To");
          return;
      }

      if (!date_from) date_from = 'empty';
      if (!date_to)  date_to = 'empty';

      let req = axios({
        method: 'post',
        url: `${utils.getBaseUrl('customer')}/report`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
        data: {
          customer_id: localStorage.getItem('customer_id'), 
          warehouse_id: localStorage.getItem('warehouse_id'),
          keyword: '',
          type,
          date_from,
          date_to
        },
      });
  
      this.setState({loading: true});
      req.then(this.downloadReportSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
    }
    downloadReportSuccess = (resp) => {
      this.setState({ loading: false });
      // alert error if any
      if (resp.data.Error) {
        this.context.alert(resp.data.Error);
        return;
      }
      let result = resp.data;
      // this.context.snackDisplay("Export report success.");
      window.open(result.url, '_blank');
    }
    // downloadClientReport = (e) => {
    //   e.preventDefault();

    //   let { client_type, client_date_from, client_date_to } = this.state;

    //   let dtFrom = new Date(client_date_from);
    //   let dtTo = new Date(client_date_to);

    //   if (dtFrom > dtTo) {
    //       this.props.alert("Date From can not be later than Date To");
    //       return;
    //   }

    //   if (!client_date_from) client_date_from = 'empty';
    //   if (!client_date_to)  client_date_to = 'empty';
    //   // let url = `${utils.getBaseUrl('customer')}/report/${localStorage.getItem('warehouse_id')}/${localStorage.getItem('customer_id')}/${client_type}/${client_date_from}/${client_date_to}`;
    //   let url = `${utils.getBaseUrl('customer')}/report/${localStorage.getItem('warehouse_id')}/${localStorage.getItem('token')}/${client_type}/${client_date_from}/${client_date_to}`;

    //   let w = window.open(url);
    //   if (!w) {
    //     this.props.alert('To enable download, please allow pop up window');
    //   } else {
    //     this.context.snackDisplay("Report download success.");
    //   }
    // }
    downloadClientReport = (e) => {
      e.preventDefault();

      let { client_type, client_date_from, client_date_to } = this.state;

      let dtFrom = new Date(client_date_from);
      let dtTo = new Date(client_date_to);

      if (dtFrom > dtTo) {
          this.props.alert("Date From can not be later than Date To");
          return;
      }

      if (!client_date_from) client_date_from = 'empty';
      if (!client_date_to)  client_date_to = 'empty';

      let req = axios({
        method: 'post',
        url: `${utils.getBaseUrl('customer')}/report`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
        data: {
          customer_id: localStorage.getItem('customer_id'), 
          warehouse_id: localStorage.getItem('warehouse_id'),
          keyword: '',
          type: client_type,
          date_from: client_date_from,
          date_to: client_date_to
        },
      });
  
      this.setState({loading: true});
      req.then(this.downloadClientReportSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
    }
    downloadClientReportSuccess = (resp) => {
      this.setState({ loading: false });
      // alert error if any
      if (resp.data.Error) {
        this.context.alert(resp.data.Error);
        return;
      }
      let result = resp.data;
      // this.context.snackDisplay("Export report success.");
      window.open(result.url, '_blank');
    }

    renderDefinitionTable = () => {
        const { classes } = this.props;
        const { client_type_options } = this.state;
        if (!client_type_options || _.isEmpty(client_type_options)) return null;

        let rows = [];
        for (let typeKey in client_type_options) {
          rows.push(client_type_options[typeKey]);
        }
      
        if (rows.length === 0 ) return null;

        return (
          <CardContainer>
            <div className={classes.title}>
                <Typography variant="h6">
                    Report Template Definitions
                </Typography>
            </div>

            <NewDataTable
              rows={rows}
              rowHeight='auto'
              noPagination
              columns={ [{
                key: 'name',
                label: 'Report Template',
            },{
                key: 'definition',
                label: 'Definition',
                width: 'auto'
            }]}
            />
          </CardContainer>
        );
    }
    renderWarehouseDefinitionTable = () => {
      const {classes} = this.props;
      const { category , type_result } = this.state;

      let rows = [];
      if (!type_result || !type_result[category]) return null;
      for (let key in type_result[category]) {
          rows.push(type_result[category][key]);
      }

      if (rows.length === 0 ) return null;

      return (
        <CardContainer>
          <div className={classes.title}>
            <Typography variant="h6">
                Report Template Definitions
            </Typography>
          </div>

          <NewDataTable
            rows={rows}
            rowHeight='auto'
            noPagination
            columns={[{
              key: 'name',
              label: 'Report Template',
            },{
              key: 'definition',
              label: 'Definition',
            }]}
          />
        </CardContainer> 
      );
    }

    renderClientForm = () => {
      const { classes } = this.props;
      const {report_type, client_type, client_type_options, client_date_from, client_date_to} = this.state;

      let type_options = [];
      for (let key of this.clientKeys) {
        let option = client_type_options[key];
        type_options.push(<MenuItem key={key} title={option.definition} value={key}>{option.name}</MenuItem>);
      }
      let dateFrom = null;
      let dateTo = null;
      if (client_type_options[client_type] && client_type_options[client_type]['required_date'] === '1') {
        dateFrom = (
          <CustomInput
            labelText='Date From'
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInput
            }}
            labelProps={{
              shrink: true,
            }}
            inputProps={{
              type: 'date',
              value: client_date_from,
              onChange: (e)=>{this.setState({client_date_from:e.target.value})}
            }}
          />
        );

        dateTo = (
          <CustomInput
            labelText='Date To'
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInput
            }}
            labelProps={{
              shrink: true,
            }}
            inputProps={{
              type: 'date',
              value: client_date_to,
              onChange: (e)=>{this.setState({client_date_to:e.target.value})}
            }}
          />
        );
      }
      
      return (
        <GridItem xs={12} noPadding>
          <CardContainer>
            <form onSubmit={this.downloadClientReport} className={classes.root}>
              <div className={classes.title}>
                <Typography variant="h6">
                    Client Report
                </Typography>
              </div>

              {
                // utils.getRoleType() == 'warehouse' && <FormControl required fullWidth className={classes.customInput}>
                //   <InputLabel shrink htmlFor="report_type">Report Type</InputLabel>
                //   <Select
                //     value={report_type}
                //     onChange={(e)=>{this.setState({report_type: e.target.value})}}
                //   >
                //     <MenuItem value="customer">Client</MenuItem>
                //     <MenuItem value="warehouse">Warehouse</MenuItem>
                //   </Select>
                // </FormControl>
              }

              <FormControl required fullWidth className={classes.customInput}>
                <InputLabel shrink>Report Template</InputLabel>
                <Select
                  value={client_type}
                  onChange={(e)=>{
                    let val = e.target.value;
                    if (client_type_options[val]['required_date'] === '0') {
                      this.setState({client_type: val, client_date_from: '', client_date_to: ''});
                    } else {
                      this.setState({client_type: val})}
                  }}
                >
                  {type_options}
                </Select>
              </FormControl>

              {dateFrom}

              {dateTo}

              <Button className={classes.button} type='submit'>Download</Button>
            </form>  
          </CardContainer> 
        </GridItem>
      );
    }
    renderWarehouseForm = () =>{
      const { classes } = this.props;
      const {report_type, type, category , type_result, date_from, date_to} = this.state;

      if (!this.keys || this.keys.length <= 0) return null;
      let category_options = [];
      for (let category_option_key in type_result) {
          category_options.push(<MenuItem key={category_option_key} value={category_option_key}>{category_option_key}</MenuItem>);
      }
      let type_options = [];
      let active_types = type_result[category] ? type_result[category] : {};
      for (let key in active_types) {
          let type_option = active_types[key];
          type_options.push(
              <MenuItem key={type_option.value} title={type_option.definition} value={type_option.value} >
                  {type_option.name}
              </MenuItem>
          );
      }

      let dateFrom = null;
      let dateTo = null;

      if (type_result[category] && type_result[category][type] && type_result[category][type]['required_date'] === '1') {
        dateFrom = (
            <CustomInput
                labelText='Date From'
                formControlProps={{
                    fullWidth: true,
                    required: true,
                    className: classes.customInput
                }}
                labelProps={{
                    shrink: true,
                }}
                inputProps={{
                    type: 'date',
                    value: date_from,
                    onChange: (e)=>{this.setState({date_from:e.target.value})}
                }}
            />
        );

        dateTo = (
            <CustomInput
                labelText='Date To'
                formControlProps={{
                    fullWidth: true,
                    required: true,
                    className: classes.customInput
                }}
                labelProps={{
                    shrink: true,
                }}
                inputProps={{
                    type: 'date',
                    value: date_to,
                    onChange: (e)=>{this.setState({date_to:e.target.value})}
                }}
            />
        );
      }

      return (
        <GridItem xs={12} noPadding>
          <CardContainer style={{margin: 0}}>
            <form onSubmit={this.downloadReport} className={classes.root}>
                <div className={classes.title}>
                  <Typography variant="h6">
                      Warehouse Report
                  </Typography>
                </div>
                {/* <FormControl required fullWidth className={classes.customInput}>
                  <InputLabel shrink htmlFor="report_type">Report Type</InputLabel>
                  <Select
                    value={report_type}
                    onChange={(e)=>{this.setState({report_type: e.target.value})}}
                  >
                  <MenuItem value="customer">Client</MenuItem>
                  <MenuItem value="warehouse">Warehouse</MenuItem>
                  </Select>
                </FormControl> */}

                <FormControl required fullWidth>
                    <InputLabel shrink={true}>Category</InputLabel>
                    <Select
                        value={category}
                        onChange={(e)=>{
                            let val = e.target.value;
                            if (val === this.state.category) return;
                            // Todo also need to update type
                            let default_type = "";
                            for (let type_key in type_result[val]) {
                                default_type = type_key;
                                break;
                            }
                            this.setState({category: val, type: default_type, date_from: '', date_to: ''})}
                        }
                    >
                        {category_options}
                    </Select>
                </FormControl>

                <FormControl required fullWidth>
                    <InputLabel shrink={true}>Report Template</InputLabel>
                    <Select
                        value={type}
                        onChange={(e)=>{
                            let val = e.target.value;
                            if (type_result[category][val]['required_date'] === '0') {
                                this.setState({type: val, date_from: '', date_to: ''});
                            } else {
                                this.setState({type: val})}
                        }}
                        inputProps={{
                            name: 'report_type',
                            id: 'report_type',
                        }}
                    >
                        {type_options}
                    </Select>
                </FormControl>

                {dateFrom}

                {dateTo}

                <Button className={classes.button} type='submit'>Download</Button>
            </form>
          </CardContainer>
        </GridItem>
      );
    }
}
export default withStyles(styles)(DownloadReport);

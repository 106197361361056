import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, ButtonGroup, IconButton, Grid, Hidden, InputLabel, Select, FormControl, MenuItem, withStyles, } from "@material-ui/core";
import {
  Edit,
  DeleteForever
} from '@material-ui/icons'
import {
  AjaxTable,
  KeyValueTable,
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
} from 'components';
import EditProduct from 'views/Dialogs/EditProduct'
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  tableDetailImage: {
    height: '80px',
    width: '80px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  buttonPushRight: {
    marginLeft: 'auto',
  },
  toolBar: {
    display: 'flex',
    paddingBottom: '.5rem',
    width: '100%'
  },
});

class ManageProduct extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    // search field input refs for uncontrolled 
    // this.searchByInput = null;
    this.searchForInput = null;

    this.temp_data = {
      barcode_map: {},
    };

    this.state = {
      loading: false,
      dialog: '',
      searchType: 'sku',
      productList: [],
      selectedProducts: {},
      singleProduct: null,
      actionLog: null,
      renderingAjax: true,
    };

    document.title = "Manage Products";
  }

  componentDidMount() {
    this.loadProductByPage();
  }

  loadProductByPage = (page, updatePage) => {
    page = page || 0;

    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/product/${page}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.loadProductByPageSuccess.bind(this, updatePage)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadProductByPageSuccess = (updatePage, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    // Some clients, for now it's only 204, 
    this.temp_data.barcode_map = (!resp.data.barcode_map || resp.data.barcode_map.length == 0) ? {} : resp.data.barcode_map;

    this.setState({productList: resp.data.products});
    if (resp.data.products.length === 0) {
      this.context.alert("Already the last page.");
      return;
    }

    if (updatePage) updatePage();
  }
  searchProduct = (item_id) => {
    let reqData = {};
    if (item_id) { // load single product by id
      reqData.type = 'item_id';
      reqData.data = item_id;
    } else {
      // let searchBy = this.searchByInput ? this.searchByInput.value : '';
      let searchBy = this.state.searchType;
      reqData.type = searchBy;
       // let searchFor = this.searchForInput ? this.searchForInput.value : '';
       let searchFor = this.searchForInput ? utils.formatString(this.searchForInput.value, {multiline: true}) : '';
      if (searchBy == 'sku') reqData.data = searchFor.split(',');
      else reqData.data = searchFor;

      if (searchBy == 'sku') {
        // Only when search by sku, if barcode map has data, use barcoed map to map 204 fake upc to correct SKU.
        let barcode_map = this.temp_data.barcode_map;
        reqData.data = reqData.data.map((elem)=>{
          if (barcode_map[elem]) return barcode_map[elem];
          else return elem;
        });
      }
      // reqData.data = searchFor;
    }
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/product`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: reqData,
    });

    this.setState({loading: true});

    let successCallback = this.searchProductSuccess; // if searching product, use the search list success handler
    if (item_id) successCallback = this.loadProductByIDSuccess; // if loading single product, use load single success handler
    req.then(successCallback).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  exportSelectedProducts = () => {
    if (_.isEmpty(this.state.selectedProducts)) {
      this.context.alert('No prodcut selected.');
      return;
    }
    let keyword_array = [];
    for (let index in this.state.selectedProducts) {
      keyword_array.push(this.state.selectedProducts[index]['sku']); // passing sku
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/exportreport`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        type: 'product',
        keyword: keyword_array
      },
    });

    this.setState({loading: true});
    req.then(this.exportSelectedProductsSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  exportSelectedProductsSuccess = (resp) => {
    this.setState({ loading: false });
    // alert error if any
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let result = resp.data;
    // this.context.snackDisplay("Export products success.");
    window.open(result.url, '_blank');
  }
  loadProductByIDSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.setState({singleProduct: resp.data.item, actionLog: resp.data.action_log});
  }
  searchProductSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    this.setState({
      productList: resp.data, 
      renderingAjax: false,
      singleProduct: null,
      actionLog: null
    });
  }
  newProduct = (newProduct) => {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/newproduct`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: newProduct,
    });

    this.setState({loading: true});
    req.then(this.newProductSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  newProductSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.context.snackDisplay("Add product success.");
    window.location.reload();
  }
  updateProduct = (newProduct) => {
    let req = axios({
      method: 'put',
      url: `${utils.getBaseUrl('customer')}/product`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: newProduct,
    });

    this.setState({loading: true});
    req.then(this.updateProductSuccess.bind(this, newProduct)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  updateProductSuccess = (newProduct, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.context.snackDisplay("Update product success.");
    this.setState({singleProduct: newProduct, dialog: ''});
  }
  deleteProduct = (item_id, deleteIndex) => {
    let req = axios({
      method: 'delete',
      url: `${utils.getBaseUrl('customer')}/product/${item_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.deleteProductSuccess.bind(this, deleteIndex)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  deleteProductSuccess = (deleteIndex, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    if (resp.data) {
      this.context.snackDisplay("Delete product success.");
      this.setState({productList: this.state.productList.filter((elem, index)=> (index != deleteIndex))});
    }
  }

  handleSearchChange = (key, val) => {
    let newSearch = Object.assign({}, this.state.search);
    newSearch[key] = val;
    this.setState({search: newSearch});
  }
  onBackToListClick = () => {
    const {singleProduct} = this.state;
    let newProductList = this.state.productList.map((elem)=>{return (elem.item_id === singleProduct.item_id) ? singleProduct : elem});
    this.setState({singleProduct: null, actionLog: null, productList: newProductList});

    // the product information might be changed by single editting, need to update the list

    utils.scrollToTop();
  }

  validateSearchForm = () => {
    // let searchFor = this.searchForInput ? this.searchForInput.value : '';
    let searchFor = this.searchForInput ? utils.formatString(this.searchForInput.value, {multiline: true}) : '';

    let err = "";
    if (!searchFor) err += "Search For input field is empty";
    // if has error, display error and stop submit
    if (err) {
      this.context.alert(err);
      return;
    }
    // if no error, submit search
    this.searchProduct();
  }

  onSingleProductChange = (key, newVal) => {
    let newProduct = Object.assign({}, this.state.singleProduct);
    newProduct[key] = newVal;
    this.setState({singleProduct: newProduct});
  }

  renderProductTable = () => {
    const {classes} = this.props;
    const {productList, selectedProducts, renderingAjax} = this.state;
    if (this.state.singleProduct || this.state.actionLog) return;

    let rows = productList;

    let columns = [
      {
        key: 'sku',
        label: 'SKU',
        width: 'auto',
        contentNoWrap: true,
        render: (sku, key, product, index)=>{
          return (
            <span>
              <Button color='default' onClick={()=>{this.searchProduct(product.item_id)}}>{sku}</Button>
             
              <IconButton color="secondary" size="small" onClick={()=>{
                  this.context.confirm("Are you sure to delete this product?", this.deleteProduct.bind(this, product.item_id, index))
              }} variant="fab" aria-label="Delete" style={{marginLeft: ".5rem"}} className={classes.button}>
                  <DeleteForever/>
              </IconButton>
            </span>
          );
        },
        colDesc: 'This is the unique identifier of product, only allow numbers, string, underscore and hyphen; Click to see more details about the product.',
      },
      {
        key: 'upc',
        label: 'UPC',
        // width: 100,
      },
      {
        key: 'item_name',
        label: 'Item Name',
        width: 'auto',
      },
      {
        key: 'retail_value',
        label: 'RetailPrice',
      },
      {
        key: 'hs_code',
        label: 'HSCode',
      },
      {
        key: 'category',
        label: 'Category',
      },
      // {
      //   key: 'sub_category',
      //   label: 'Subcategory',
      // },
      {
        key: 'image',
        label: 'Image',
        contentNoWrap: true,
        render: (val, key, row)=>{
          if (val) {
            return (<img className={classes.tableImage} alt="" title='click to zoom' style={{cursor: 'pointer'}} onClick={()=>{
              this.context.alert(<img src={val} alt="wrong url" style={{width: '100%'}} />, {type: row.sku || 'Product Image'});
            }} src={val}/>);
          } else {
            return "No Image";
          }
        }
      },
      // {
      //   key: 'style',
      //   label: 'Style',
      // },
      // {
      //   key: 'unit_case',
      //   label: 'UnitCase',
      // },
    ];

    let table = (
      <AjaxTable
        rows={rows}
        // rowHeight={50} 
        id='table-export'
        columns={columns}
        handlePageChange={this.loadProductByPage}
      />
    );
    if (!renderingAjax) {
      table = (
        <NewDataTable
          rows={rows}
          id='table-export'
          // rowHeight={50} 
          columns={columns}
          rowSelection={{
            selected: selectedProducts, // since ajax table will not have sort by, data array index will always work for selection
            handleSelectedChange: (newSelected) => (this.setState({selectedProducts: newSelected})),
            uniqueID: 'item_id'
          }}
        />
      );
    }

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CardContainer>
          <div className={classes.toolBar}>
            {rows.length > 0 && <Button className={classes.buttonPushRight} onClick={()=>{
              if (renderingAjax) utils.exportTableToCsv('table-export');
              else this.exportSelectedProducts();
            }}>Export</Button>}
          </div>

          {table}
        </CardContainer>
      </GridItem>
    );
  }
  renderSingleProduct = () => {
    const {classes} = this.props;
    const {singleProduct, actionLog} = this.state;
    if (!singleProduct || !actionLog) return null;

    let propertyList = [
      {
        key: 'item_name',
        label: 'Item Name',
      },
      {
        key: 'sku',
        label: 'SKU',
      },
      {
        key: 'upc',
        label: 'UPC',
      },
      {
        key: 'retail_value',
        label: 'Retail Value',
      },
      {
        key: 'wholesale_value',
        label: 'Wholesale Value',
      },
      {
        key: 'cost',
        label: 'Cost',
      },
      {
        key: 'ldp_cost',
        label: 'LDP Cost',
      },
      {
        key: 'category',
        label: 'Category',
      },
      {
        key: 'sub_category',
        label: 'Subcategory',
      },
      {
        key: 'collection',
        label: 'Collection',
      },
      {
        key: 'style',
        label: 'Style',
      },
      {
        key: 'size',
        label: 'Size',
      },
      {
        key: 'color',
        label: 'Color',
      },
      {
        key: 'material',
        label: 'Material',
      },
      {
        key: 'manufacturer',
        label: 'Manufacturer',
      },
      {
        key: 'country_origin',
        label: 'Country of Origin',
      },
      {
        key: 'image',
        label: 'Image',
        render: (val, key, row)=>{
          if (val) {
            return (<img className={classes.tableDetailImage} alt="wrong url" title='click to zoom' style={{cursor: 'pointer'}} onClick={()=>{
              this.context.alert(<img src={val} alt="wrong url" style={{width: '100%'}} />, {type: row.sku || 'Product Image'});
            }} src={val}/>);
          } else {
            return "No Image";
          }
        }
      },
      {
        key: 'weight',
        label: 'Weight',
      },
      {
        key: 'dim_length',
        label: 'Length',
      },
      {
        key: 'dim_width',
        label: 'Width',
      },
      {
        key: 'dim_height',
        label: 'Height',
      },
      {
        key: 'oversize',
        label: 'Oversize',
        render: (val, key, row)=>{
          if (val == 1) {
            return "Yes";
          } else {
            return "No";
          }
        }
      },
      {
        key: 'hs_code',
        label: 'HS Code',
      },
      {
        key: 'unit_case',
        label: 'Unit Case',
      },
      {
        key: 'min_unit',
        label: 'Min Unit',
      },
      {
        key: 'max_unit',
        label: 'Max Unit',
      },
      // {
      //   key: 'created_dt',
      //   label: 'Created Date',
      //   render: utils.localizeDate
      // },
      {
        key: 'short_description',
        label: 'Short Description',
        width: 'auto',
      },
      {
        key: 'updated_dt',
        label: 'Updated Date',
        render: utils.localizeDate
      },
    ];

    return (
      <React.Fragment>
        <GridItem xs={12} sm={6} md={6}>
          <CardContainer>
            <div className={classes.toolBar}>
              <ButtonGroup>
                <Button color="bxzDefault" variant="contained" onClick={this.onBackToListClick}>Go Back</Button>
                <Button color="bxzDefault" variant="contained" className={classes.buttonPushRight} onClick={()=>{this.setState({dialog: 'edit'})}}>Edit</Button>
              </ButtonGroup>
            </div>
            <KeyValueTable
              propertyList={propertyList}
              dataObj={singleProduct}
            />
          </CardContainer>
        </GridItem>

        <GridItem xs={12} sm={6} md={6}>
          <CardContainer>
            <Typography variant="h6" style={{marginBottom: '1.125rem'}}>Action Log</Typography>
            <NewDataTable
              rows={actionLog}
              noPagination
              maxHeight={500}
              columns={[
                {
                  key: 'fullname',
                  label: 'User',
                },{
                  key: 'notes',
                  label: 'Notes'
                }, {
                  key: 'created_dt',
                  label: 'Date',
                  render: utils.localizeDate
                }
              ]}
            />
          </CardContainer>
        </GridItem>
      </React.Fragment>
    );
  }
  renderDialog = () => {
    const {dialog, singleProduct} = this.state;
    switch (dialog) {
      case 'add':
        return (
          <EditProduct
            product={{
              item_id: '',
              sku: '',
              upc: '',
              item_name: '',
              retail_value: '',
              wholesale_value: '',
              cost: '',
              ldp_cost: '',
              hs_code: '',
              dim_length: '',
              dim_width: '',
              dim_height: '',
              weight: '',
              category: '',
              sub_category: '',
              min_unit: '',
              max_unit: '',
              style: '',
              size: '',
              color: '',
              material: '',
              oversize: 0,
              manufacturer: '',
              country_origin: '',
              image: '',
              collection: '',
              unit_case: '',
            }}
            handleSubmit={this.newProduct}
            handleClose={()=>{this.setState({dialog: ''});}}
          /> 
        )
      case 'edit': 
      return (
        <EditProduct
          product={singleProduct}
          handleSubmit={this.updateProduct}
          handleClose={()=>{this.setState({dialog: ''});}}
        /> 
      )
      default:
          return null;
    }
  }

  render() {
    const {classes} = this.props; 
    const {loading, searchType} = this.state;

    return (
      <Grid container spacing={2}>
        {loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
              
        {this.renderDialog()}

        <GridItem xs={12} sm={6} md={3}>
          <CardContainer>
            <div>
              <FormControl fullWidth required className={classes.customInput}>
                <InputLabel shrink>Search By</InputLabel>
                <Select
                  value={searchType}
                  onChange={(e)=>{this.setState({searchType: e.target.value})}}
                  // defaultValue="sku"
                  // inputRef={(el)=>{this.searchByInput = el}}
                >
                  <MenuItem value='sku'>SKU/UPC</MenuItem>
                  <MenuItem value='item_name'>Item Name</MenuItem>
                  <MenuItem value='style'>Style</MenuItem>
                  <MenuItem value='category'>Category</MenuItem>
                  <MenuItem value='other'>Other Fields</MenuItem>
                </Select>
              </FormControl>

              {
                searchType == 'sku' ? 
                <CustomInput
                  labelText='Search For'
                  labelProps={{shrink: true}}
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                    className: this.props.classes.customInput
                  }}
                  inputProps={{
                  inputRef: (el)=>{this.searchForInput = el},
                  defaultValue: "",
                    placeholder: 'Use Linebreak to separate multiple keywords',
                    multiline: true,
                    rows: 3
                  }}
                /> : <CustomInput
                      labelText='Search For'
                      labelProps={{shrink: true}}
                      formControlProps={{
                        fullWidth: true,
                        required: true,
                        className: this.props.classes.customInput
                      }}
                      inputProps={{
                        inputRef: (el)=>{this.searchForInput = el},
                        defaultValue: "",
                      }}
                    />
              }
              <div className={classes.toolBar} style={{marginTop: '.5rem'}}>
                <Button onClick={this.validateSearchForm}>Search</Button>
                <Button className={classes.buttonPushRight} color="primary" onClick={()=>{this.setState({dialog: 'add'})}}>Add New</Button>
              </div>
            </div>
          </CardContainer>
        </GridItem>

        <Hidden smDown><GridItem xs={12} sm={6} md={9} /></Hidden>
        
        {this.renderSingleProduct()}
        {this.renderProductTable()}
      </Grid>
    );
  }
}
export default withStyles(styles)(ManageProduct);
